import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'forgot', loadComponent: () => import('./forgot-password/forgot-password.component').then(c=>c.ForgotPasswordComponent) },
    { path: 'dashboard', loadComponent: () => import('./admin.component').then(c=>c.AdminComponent),
    children: [
        { path: "", pathMatch: "full", redirectTo: 'index' },
        { path: 'index',  loadComponent: () => import('./rservicios/rservicios.component')
          .then(c => c.RServiciosComponent)},
        { path: 'service/:id',  loadComponent: () => import('./service-list/service-list.component')
          .then(c => c.ServiceListComponent)},
        { path: 'dns',  loadChildren: () => import('./dns/dns-routing.module')
          .then(m => m.DnsRoutingModule)},
        { path: 'carrito',  loadChildren: () => import('./carrito/carrito-routing.module')
          .then(m => m.CarritoRoutingModule)},
        { path: 'email',  loadChildren: () => import('./email/email-routing.module')
          .then(m => m.EmailRoutingModule)},
        { path: 'cdn',  loadChildren: () => import('./cdn/cdn-routing.module')
          .then(m => m.CdnRoutingModule)},
        { path: 'dedicated',  loadChildren: () => import('./dedicated/dedicated-routing.module')
          .then(m => m.DedicatedRoutingModule)},
        { path: 'lb',  loadChildren: () => import('./load-balancer/load-balancer-routing.module')
          .then(m => m.LoadBalancerRoutingModule)},
        { path: 'vps',  loadChildren: () => import('./vps/vps-routing.module')
          .then(m => m.VpsRoutingModule)},
    ]
    },
    { path: '**', loadComponent: () => import('./not-found/not-found.component').then(c=>c.NotFoundComponent) },     
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
